<template>
  <div
    :class="{'general-container': true, 'grey-background': greyBackground }"
  >
    <b-loading
      v-if="loadingInitialData || (alwaysLoading && !accessDenied) || redirecting"
      :is-full-page="true"
      :active="true"
    />
    <template v-else>
      <the-navbar :anonymous="isAnonymousProject" />
      <div
        v-if="projectEnded"
        class="extra-top-padding page-bottom-padding-extra"
      >
        <centered-image-and-text-module
          v-if="isAnonymousProject"
          image="project-ended.png"
          title="¡El proyecto ha finalizado!"
          button-text="Ir a Relevant"
          button-link="https://joinrelevant.com"
        >
          El proyecto al que intentas acceder ha finalizado. Encontrá mas información sobre
          nuestros productos, servicios y beneficios.
        </centered-image-and-text-module>
        <centered-image-and-text-module
          v-else
          image="project-ended.png"
          title="¡El proyecto ha finalizado!"
          button-text="Ver mis proyectos"
          :button-link="{ name: 'proyectos' }"
        >
          El proyecto al que intentas acceder ha finalizado.
        </centered-image-and-text-module>
      </div>
      <div
        v-else-if="noNewParticipantsAllowed"
        class="extra-top-padding page-bottom-padding-extra"
      >
        <centered-image-and-text-module
          image="participation-limit-reached.png"
          title="¡Los cupos estan completos!"
          button-text="Ir a Relevant"
          button-link="https://joinrelevant.com"
        >
          Todos los cupos de los participantes estan completos. Encontrá mas información sobre
          nuestros productos, servicios y beneficios.
        </centered-image-and-text-module>
      </div>
      <div
        v-else-if="projectNotFound || !isActive"
        class="extra-top-padding page-bottom-padding-extra"
      >
        <not-found />
      </div>
      <slot v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CenteredImageAndTextModule from '@/components/CenteredImageAndTextModule.vue'
import NotFound from '@/components/NotFound.vue'
import TheNavbar from '@/components/TheNavbar.vue'

export default {
  name: 'ParticipationViewContentContainer',
  components: {
    CenteredImageAndTextModule,
    NotFound,
    TheNavbar
  },
  props: {
    alwaysLoading: {
      type: Boolean,
      default: false
    },
    greyBackground: {
      type: Boolean,
      default: false
    },
    currentStage: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('participationProject', ['isAnonymousProject', 'isActive', 'projectEnded']),
    ...mapState('participationProject', ['projectNotFound']),
    ...mapState('participationParticipant', ['noNewParticipantsAllowed', 'accessDenied']),
    ...mapState('participationParticipation', ['redirecting']),
    projectIdentifier() {
      return this.$route.params.projectIdentifier
    },
    loadingInitialData() {
      const { state } = this.$store

      if (state.participationProject.projectNotFound) return false

      return (
        !state.participationProject.projectData
        || (state.participationProject.projectData.identifier !== this.projectIdentifier)
        || (!state.participationParticipant.participantData && !this.noNewParticipantsAllowed)
      )
    }
  },
  async created() {
    // Fetch the project & participation data and, if necessary, route the participant to the
    // corresponding URL according to the project status and their progress in their participation
    const params = {
      projectIdentifier: this.projectIdentifier,
      currentStage: this.currentStage,
      owid: this.$route.query.owid || null
    }

    await this.$store.dispatch('participationParticipant/routeParticipant', params)
  }
}
</script>

<style lang="scss" scoped>
.general-container {
  .float-bottom-navbar {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 23px;
  }
}
</style>
